import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { TotemForm } from './TotemForm';
import { useDispatch, useSelector } from 'react-redux';
import { setItems, selectEspacos } from '../../features/espacos/espacosSlice';
import "./Totens.css";
const API_URL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const Totens = () => {
    const dispatch = useDispatch();

    useEffect( () => {
        axios.get(`${API_URL}/espacos`)
            .then(({ data }) => {
                
                dispatch(setItems(data.sort((a, b) => a.id - b.id)))
            })
            .catch(console.warn)
    },[])
    const totens = useSelector(selectEspacos)
    return  <section className='container'>
                { totens.map((item, i) => {
                   return <div className='totem_block' key={item.id}>
                        <TotemForm totemName={`Totem ${i + 1}`} {...item}/>
                    </div>
                })}
            </section>
}