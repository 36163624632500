import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import espacosReducer from '../features/espacos/espacosSlice';
import conteudosReducer from '../features/conteudos/conteudosSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    espacos: espacosReducer,
    conteudos: conteudosReducer,
  },
});
