import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};


export const espacosSlice = createSlice({
  name: 'espacos',
  initialState,
  reducers: {
    setItems:(state, action) => {
      return {...state, items: action.payload}
    }
  },
});

export const { setItems } = espacosSlice.actions;

export const selectEspacos = (state) => state.espacos.items;

export default espacosSlice.reducer;
