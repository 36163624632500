import axios from 'axios';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { reducedByGroup } from '../../data/espacoTypes';
import "./TotensForm.css";

const defaultValues = {
    active: true,
    nome: '',
    tipo: 1
}

const API_URL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;

export const TotemForm = ({ totemName, id, nome, active, tipo }) => {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            active: +active,
            nome: nome,
            tipo: tipo,
            area: id
        }
    });

    const sendRequest = (data) => {
        console.log(data);
        axios.put(`${API_URL}/espaco/update/${id}`, data)
            .then(console.log)
            .catch(console.warn)
    }

    const onSuccess = (data) => {
        sendRequest({ ...data, tipo: Number(data.tipo), active: data.active ? 1 : 0 });
    }

    const onError = (error) => {
        console.log(error);
    }
    const inputRef = useRef();

    const tipos = reducedByGroup();

    return  <form onSubmit={handleSubmit(onSuccess, onError)}>
                <h3>{totemName}</h3>
                <input type="checkbox" className="apple-switch" {...register('active') }/>
                <div>
                    <div className="input_block">
                        <label>Nome</label>
                        <input {...register('nome', { required: false })}/>
                    </div>
                    <div className="input_block">
                        <label>Tipo</label>
                        <select {...register('tipo', { required: false })} >
                            {Object.keys(tipos).map((key, i) => tipos[key].map( item => <option selected={item.value === tipo}  value={item.value} key={item.value}>{item.nome}</option> ) )}
                        </select>
                    </div>
                </div>
                <button type="submit">Salvar</button>
            </form>
}