import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};


export const conteudosSlice = createSlice({
  name: 'conteudos',
  initialState,
  reducers: {
    setItems:(state, action) => {
      return {...state, items: action.payload}
    }
  },
});

export const { setItems } = conteudosSlice.actions;

export const selectConteudos = (state) => state.conteudos.items;

export default conteudosSlice.reducer;
