export const espacoTypes = [
    {
        group: 'PDF',
        nome: 'Único PDF',
        value: 1   
    },
    {
        group: 'PDF',
        nome: 'Múltiplos PDFs',
        value: 2
    },
    {
        group: 'Vídeo',
        nome: 'Único Vídeo',
        value: 3   
    },
    {
        group: 'Vídeo',
        nome: 'Múltiplos Vídeos',
        value: 4   
    },
    {
        group: 'Imagem',
        nome: 'Banner',
        value: 5  
    },
    {
        group: 'Imagem',
        nome: 'Carrossel',
        value: 6  
    },
    {
        group: 'Contato',
        nome: 'WhatsApp',
        value: 7  
    },
    {
        group: 'Contato',
        nome: 'Completo (e-mail, telefone, endereço)',
        value: 8
    }
];

export const reducedByGroup = () => {
    
    return espacoTypes.reduce((acc, value) => {
        if(acc[value.group] === undefined){
            acc[value.group] = []
        }
        acc[value.group].push(value)
        return acc
    },{})
}