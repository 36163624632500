import React from 'react';
import axios from 'axios';
import './App.css';
import { Espacos } from './componentes/Espacos/Espacos';
import { Conteudos } from './componentes/Conteudos/Conteudos';
import { Totens } from './componentes/Totens/Totens';


const API_URL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL;
function App() {

  const publicar = () => {
    console.log(API_URL)
    axios.get(`${API_URL}/build`)
      .then(console.log)
      .catch(console.log)
  }

  return (
    <div >
      <header>
        <h1>Protótipo da ferramenta</h1>
        <button type="submit" onClick={publicar}>Publicar</button>
      </header>
      <div>
        <Totens />
      </div>
    </div>
  );
}

export default App;
